var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "SSRAgTable",
        _vm._g(
          {
            attrs: {
              frameworkComponents: _vm.frameworkComponents,
              columns: _vm.columnDefs,
              data: _vm.rowData,
              totalRows: _vm.totalRows,
              totalPages: _vm.totalPages,
              isLoading: _vm.isLoading,
            },
            on: {
              fetchData: _vm.getGridData,
              updateSelectedItems: _vm.onSelectedItems,
            },
          },
          _vm.$listeners
        )
      ),
      _c("show-docs-popup", {
        attrs: {
          poModalActive: _vm.poModalActive,
          poModalData: _vm.poModalData,
          title: "Cobros",
        },
        on: {
          "update:poModalActive": function ($event) {
            _vm.poModalActive = $event
          },
          "update:po-modal-active": function ($event) {
            _vm.poModalActive = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }