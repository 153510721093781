var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vx-card",
        { staticClass: "campaigns-container" },
        [
          _c(
            "vx-card",
            {
              staticClass: "mb-2 campaign-filters",
              attrs: { title: "Filtros" },
            },
            [
              _c(
                "vs-row",
                {
                  attrs: {
                    "vs-align": "flex-end",
                    "vs-type": "flex",
                    "vs-justify": "space-between",
                    "vs-w": "12",
                  },
                },
                [
                  _c(
                    "vs-col",
                    { attrs: { "vs-w": "11" } },
                    [
                      _c(
                        "vs-row",
                        { staticClass: "gap-4 items-end" },
                        [
                          _c(
                            "vs-col",
                            { attrs: { "vs-w": "3" } },
                            [
                              _c("country-select", {
                                attrs: { defaultSelectedCountry: "all" },
                                on: {
                                  value: function ($event) {
                                    _vm.filters.countries = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "vs-col",
                            { attrs: { "vs-w": "3" } },
                            [
                              _c("holding-select", {
                                on: {
                                  value: function ($event) {
                                    _vm.filters.holdings = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "vs-col",
                            { attrs: { "vs-w": "3" } },
                            [
                              _c("billing-moment-select", {
                                on: {
                                  value: function ($event) {
                                    _vm.filters.billingMoment = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vs-col",
                    { attrs: { "vs-w": "1" } },
                    [
                      _c(
                        "vs-col",
                        { attrs: { "vs-w": "12" } },
                        [
                          _c(
                            "vs-button",
                            {
                              staticClass: "mr-3 mb-2",
                              on: { click: _vm.searchData },
                            },
                            [_vm._v("Buscar")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("clients-table", {
            ref: "clientsTable",
            attrs: { filters: _vm.filters },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }