import { campaignInvoicesMapping, campaignChargesMapping} from '../misc'
import { agencyCommissionMethodGetter, agencyProfitGetter, agencyProfitPercentagetoolTipValueGetter, amountReceivableWithTaxGetter, billingDateGetter, billingMethodGetter, campaignTypeNameGetter, countryGetter, currencyCheck, currencyFormatter, endDateGetter, exchangeProfitGetter, externalDspGetter, fullNameGetter, holdingIdGetter, holdingNameGetter, idClientGetter, legalNameGetter, paymentODOOMethodGetter, platformProfitGetter, platformProfitPercentagetoolTipValueGetter, startDateGetter, taxGetter, timeLimitGetter} from '../strings'

const cellStyles = {
  billingDate: {backgroundColor: '#ab85ad', color: '#fff'},
  amountReceivableWithoutTax: {backgroundColor: '#eebcbc', color: '#545454'},
  amountReceivableWithTax: {backgroundColor: '#ef9a9a', color: '#fff'}
}


export const columnDefs = ({openPoModal})=> ([
    {
      headerName: 'Campaña',
      headerClass: 'column-group-1',
      children: [
        {
          headerName: 'ID',
          field: 'id',
          width: 150,
          filter: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true,
          pinned: 'left'
        },
        {
          headerName: 'Nombre Campaña',
          field: 'name',
          filter: false,
          width: 300,
          wrapText: true
        },
        {
          headerName: 'DSP',
          valueGetter: externalDspGetter,
          filter: true,
          width: 200,
          wrapText: true
        },
        {
          headerName: 'Fecha de facturación',
          valueGetter: billingDateGetter,
          filter: true,
          width: 200,
          wrapText: true,
          headerClass: 'column-group-1',
          cellStyle: cellStyles.billingDate,
        },
        {
          headerName: 'Fecha de Inicio',
          valueGetter: startDateGetter,
          filter: false,
          width: 180
        },
        {
          headerName: 'Fecha de Fin',
          valueGetter: endDateGetter,
          filter: false,
          width: 150
        },
        {
          headerName: 'Tipo de campaña',
          field: 'smart_campaign',
          valueGetter: campaignTypeNameGetter,
          filter: false,
          width: 150
        },
        {
          headerName: 'País',
          valueGetter: countryGetter,
          filter: true,
          width: 200
        }
      ]
    },
    {
      headerName: 'Operación',
      headerClass: 'column-group-2',
      children: [
        {
          headerName: 'Divisa',
          field: 'currency',
          width: 100,
          filter: true
        },
        {
          headerName: 'Total presupuestado',
          field: 'total_price_with_profit',
          valueFormatter: currencyFormatter,
          filter: false,
          width: 350,
        },
        {
          headerName: 'Total consumido',
          field: 'total_price_consumed',
          valueFormatter: currencyFormatter,
          filter: false,
          width: 350,
          colId: 'total_price_consumed'
        },
        {
          headerName: 'Pago pantalleros',
          field: 'total_price',
          valueFormatter: currencyFormatter,
          filter: false,
          width: 260
        },
        {
          headerName: 'Ganancia plataforma',
          valueGetter: platformProfitGetter,
          valueFormatter: currencyFormatter,
          tooltipComponent: 'profitPercentageTooltip',
          tooltipValueGetter: platformProfitPercentagetoolTipValueGetter,
          filter: false,
          width: 230
        },
        {
          headerName: 'Ganancia agencia',
          valueGetter: agencyProfitGetter,
          valueFormatter: currencyFormatter,
          tooltipComponent: 'profitPercentageTooltip',
          tooltipValueGetter: agencyProfitPercentagetoolTipValueGetter,
          filter: false,
          width: 180
        },
        {
          headerName: 'Ganancia Exchange',
          valueGetter: exchangeProfitGetter,
          valueFormatter: currencyFormatter,
          filter: false,
          width: 230
        },
        {
          headerName: 'Cotización del día',
          field: 'currency_rate',
          valueFormatter: currencyCheck,
          filter: false,
          width: 250
        },
        {
          headerName: 'Tax',
          valueGetter: taxGetter,
          filter: false,
          width: 150
        },
        // {
        //   headerName: 'Precio Final C/I',
        //   valueGetter: campaignCostWithTaxGetter,
        //   valueFormatter: currencyFormatter,
        //   filter: 'agNumberColumnFilter',
        //   width: 350
        // },
        {
          headerName: 'Monto a facturar S/I',
          field: 'total_price_with_commission',
          valueFormatter: currencyFormatter,
          filter: false,
          cellStyle: cellStyles.amountReceivableWithoutTax,
          width: 280
        },
        {
          headerName: 'Monto a facturar C/I',
          valueGetter: amountReceivableWithTaxGetter,
          valueFormatter: currencyFormatter,
          filter: false,
          cellStyle: cellStyles.amountReceivableWithTax,
          width: 280
        }
      ]
    },
    {
      headerName: 'Datos Facturación',
      headerClass: 'column-group-3',
      children: [
        {
          headerName: 'Documentacion',
          field: 'purchase_order_agency',
          filter: false,
          width: 120,
          hide: false,
          cellStyle: {textAlign: 'center'},
          cellRenderer: (params) => {
            if (params.value) {
              const button = document.createElement('a')
              button.innerText = 'Ver'
              button.addEventListener('click', () => openPoModal(params.value))
              return button
            }
          }
        },
        {
          headerName: 'ID Holding',
          valueGetter: holdingIdGetter,
          filter: false,
          width: 200
        },
        {
          headerName: 'Holding agencia',
          valueGetter: holdingNameGetter,
          filter: true,
          width: 200
        },
        {
          headerName: 'Anunciante',
          valueGetter: fullNameGetter,
          filter: true,
          width: 200
        },
        //From ODOO
        {
          headerName: 'ID anunciante',
          valueGetter: idClientGetter,
          filter: true,
          width: 200
        },
        {
          headerName: 'Razón social',
          valueGetter: legalNameGetter,
          filter: false,
          width: 200
        },
        {
          headerName: 'Modo de facturación',
          valueGetter: billingMethodGetter,
          filter: false,
          width: 200
        },
        {
          headerName: 'Modo de pago',
          valueGetter: paymentODOOMethodGetter,
          filter: false,
          width: 200
        },
        {
          headerName: 'Plazo de pago',
          valueGetter: timeLimitGetter,
          filter: false,
          width: 200
        },
        {
          headerName: 'Modo comisión agencia',
          valueGetter: agencyCommissionMethodGetter,
          filter: false,
          width: 250
        },
        //
        {
          headerName: 'Facturado',
          field: 'invoiced',
          cellRenderer: 'invoicedRenderer',
          width: 120,
          hide: false,
          cellStyle: {paddingLeft: '5px', paddingRight: '5px'},
          pinned: 'left',
          filter: true,
          refData: campaignInvoicesMapping()
        },
        {
          headerName: 'Cobrado',
          field: 'charged',
          cellRenderer: 'chargedRenderer',
          width: 120,
          hide: false,
          cellStyle: {paddingLeft: '5px', paddingRight: '5px'},
          pinned: 'left',
          filter: true,
          refData: campaignChargesMapping()
        },
      ]
    },
  ])