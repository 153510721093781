import { campaignChargesMapping } from '../misc'
import {
  amountReceivableWithTaxGetter,
  billingDateGetter,
  countryGetter,
  currencyFormatter,
  fullNameGetter,
  holdingNameGetter,
  billingNumberGetter,
  legalNameGetter,
  latePaymentGetter
} from '../strings'

const cellStyles = {
  billingDate: { backgroundColor: '#ab85ad', color: '#fff' },
  amountReceivableWithoutTax: { backgroundColor: '#eebcbc', color: '#545454' },
  amountReceivableWithTax: { backgroundColor: '#ef9a9a', color: '#fff' }
}

const getFilesTestData = (params) => {
  const {data} = params
  const {purchase_order_agency, id} = data
  if(purchase_order_agency){
    const parseData = JSON.parse(purchase_order_agency)
    return {
      campaign_id: id,
      files: JSON.stringify(parseData.map((fileData, index) => ({
        id: index,
        file: fileData.file,
        created_at: fileData.created_at,
        billed_at: null,
        billed: false,
        charged_at: null,
        charged: false,
        null: false
      })))
    }
  }
  return {
    campaign_id: id,
    files: JSON.stringify([])
  }
}

export const columnDefs = ({ openPoModal }) => [
  {
    headerName: 'ID',
    field: 'id',
    width: 150,
    filter: true,
    checkboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    headerCheckboxSelection: true,
    pinned: 'left'
  },
  {
    headerName: 'Cobrado',
    valueGetter: getFilesTestData,
    cellRenderer: 'billingAmountRenderer',
    cellRendererParams: {
      variant: 'charged'
    },
    width: 120,
    hide: false,
    cellStyle: { paddingLeft: '5px', paddingRight: '5px' },
    pinned: 'left',
    filter: true,
    refData: campaignChargesMapping()
  },
  {
    headerName: 'Holding agencia',
    valueGetter: holdingNameGetter,
    filter: true,
    width: 200
  },
  {
    headerName: 'Anunciante',
    valueGetter: fullNameGetter,
    filter: true,
    width: 200
  },
  //From ODOO
  {
    headerName: 'Razón social',
    valueGetter: legalNameGetter,
    filter: false,
    width: 200
  },
  {
    headerName: 'Número de factura',
    valueGetter: billingNumberGetter,
    filter: false,
    width: 200
  },
  {
    headerName: 'Nombre Campaña',
    field: 'name',
    filter: false,
    width: 300,
    wrapText: true
  },
  {
    headerName: 'Fecha de facturación',
    valueGetter: billingDateGetter,
    filter: true,
    width: 200,
    wrapText: true,
    headerClass: 'column-group-1'
  },
  {
    headerName: 'Fecha de vencimiento',
    valueGetter: billingDateGetter,
    filter: true,
    width: 200,
    wrapText: true,
    headerClass: 'column-group-1'
  },
  {
    headerName: 'Atraso',
    valueGetter: latePaymentGetter,
    filter: true,
    width: 200,
    wrapText: true,
    headerClass: 'column-group-1'
  },
  {
    headerName: 'País',
    valueGetter: countryGetter,
    filter: true,
    width: 200
  },

  {
    headerName: 'Divisa',
    field: 'currency',
    width: 100,
    filter: true
  },
  {
    headerName: 'Monto a cobrar C/I',
    valueGetter: amountReceivableWithTaxGetter,
    valueFormatter: currencyFormatter,
    filter: 'agNumberColumnFilter',
    cellStyle: cellStyles.amountReceivableWithoutTax,
    width: 285
  },
  {
    headerName: 'Monto a cobrar S/I',
    field: 'total_price_with_commission',
    valueFormatter: currencyFormatter,
    cellStyle: cellStyles.amountReceivableWithTax,
    filter: 'agNumberColumnFilter',
    width: 280
  },
  {
    headerName: 'Documentacion',
    valueGetter: getFilesTestData,
    filter: false,
    width: 120,
    hide: false,
    cellStyle: { textAlign: 'center' },
    cellRenderer: (params) => {
      if (params.value) {
        const button = document.createElement('a')
        button.innerText = 'Ver'
        button.addEventListener('click', (event) => {
          event.stopPropagation()
          openPoModal(params.value)
        })
        return button
      }else{
        return '-'
      }
    }
  },
]
