<template>
    <div class="billing-moment-select">
        <p>Modo de facturación:</p>
        <v-select
            id="type"
            label="text"
            :options="billingMoments"
            :clearable="false"
            v-model="billingMomentSelected"
            @input="setBillingMoments"
        >
            <span slot="no-options">Nada que mostrar.</span>
        </v-select>
    </div>
</template>

<style>
    .billing-moment-select .vs--single .vs__selected {
        white-space: nowrap;
        /* max-width: 99%; */
        overflow: hidden;
    }
    .billing-moment-select .vs__selected-options{
        flex-wrap: nowrap;
        overflow: hidden;
    }
</style>

<script>
    import vSelect from 'vue-select'
    
    export default {
        components: {
            vSelect
        },
        data () {
            return {
                //default value
                billingMoments: [
                    { value: 'all', text: 'Todos' },
                    { value: 'full_campaign_start_100', text: 'Inicio de campaña completo por el 100%' },
                    { value: 'campaign_start_partial_months', text: 'Inicio de campaña parcial por meses' },
                    { value: 'campaign_completed_no_monthly_break', text: 'Terminada la campaña sin corte mensual' },
                    { value: 'monthly_closure', text: 'Cierre mensual' }
                ],
                billingMomentSelected: { value: 'all', text: 'Todos' },
            }
        },
        methods: {
            setBillingMoments () {
                this.$emit('value', this.billingMomentSelected)
            },
        },
        mounted() {
            this.setBillingMoments()
        }
    }
</script>
