<template>
    <div class="holding-select">
        <p>Seleccionar Holding:</p>
        <v-select
            id="type"
            label="text"
            :options="holdings"
            :clearable="false"
            multiple
            v-model="holdingsSelected"
            @input="setHoldings"
            :disabled="loading"
        >
            <span slot="no-options">Nada que mostrar.</span>
        </v-select>
    </div>
  </template>
  
  <script>
    import vSelect from 'vue-select'
    import { sortAlphabetize } from '@/utils/utils.js'
    import appConfig from '../../../appConfig';
    
  export default {
    components: {
        vSelect
    },
    data () {
        return {
            //default value
            holdings: [],
            holdingsSelected: [{ value: 'all', text: 'Todos', companies: [] }],
            loading: false
        }
    },
    methods: {
        getHoldings () {
            this.loading = true
            this.$http.get(`${appConfig.apiUrl}/panel/holdings`)
            .then((response) => {
                //mapping response
                this.holdings = response.data.map((holding) => ({
                    text: holding.name,
                    value: holding.id,
                    companies: holding.companies
                }))
                this.holdings = sortAlphabetize(this.holdings, 'text')
                this.holdings.unshift({ value: 'all', text: 'Todos' })

                this.setHoldings()
            })
            .catch((error) => {
                console.log(error)
            }).finally(() => {
                this.loading = false
            })
        },
        clearCompanies () {
            this.$emit('companies', [])
            this.$emit('clearCompanies', {})
        },
        setCompanies () {
            let companies = []
            this.clearCompanies()
            this.holdingsSelected.forEach((holding) => {
                if (holding.companies) {
                    const companiesHolding = holding.companies.map((company) => ({
                        value: company.id,
                        text: company.name
                    }))

                    companies = [...companies, ...companiesHolding]
                }
            })
            this.$emit('companies', companies)
        },
        setHoldings () {
            this.existOptionAll()
            this.setCompanies()
            this.$emit('value', this.holdingsSelected)
        },
        existOptionAll () {
            const positionOfAll = this.holdingsSelected.map(holding => holding.value).indexOf('all')
            if (positionOfAll === 0) this.holdingsSelected.splice(positionOfAll, 1)
            if ((positionOfAll > 0) || (this.holdingsSelected.length === 0)) this.holdingsSelected = [{value : 'all', text : 'Todos'}]
        }
    },
    mounted() {
        this.getHoldings()
    }
}
</script>
