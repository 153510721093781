import { agencyCommissionModeMapping, billingMomentMapping, paymentModeMapping } from '../misc'
import {
  addressGetter,
  agencyCommissionModeGetter,
  billingMomentGetter,
  chargeTimeLimitGetter,
  fantasyNameGetter,
  holdingIdGetter,
  holdingNameGetter,
  idClientGetter,
  legalNameGetter,
  paymentModeGetter,
  paymentTimeLimitGetter,
  taxIdGetter,
} from '../strings'

export const columnDefs = () => [
  {
    headerName: 'Datos del cliente',
    headerClass: 'column-group-1',
    children: [
        {
            headerName: 'ID',
            field: 'id',
            width: 150,
            filter: true,
            checkboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            headerCheckboxSelection: true,
            pinned: 'left'
          },
          {
            headerName: 'Razón social',
            valueGetter: legalNameGetter,
            filter: false,
            width: 200
          },
          //From ODOO
          {
            headerName: 'ID anunciante',
            valueGetter: idClientGetter,
            filter: true,
            width: 200
          },
          {
            headerName: 'Nombre de fantasía',
            valueGetter: fantasyNameGetter,
            filter: true,
            width: 200
          },
          {
            headerName: 'ID Holding',
            valueGetter: holdingIdGetter,
            filter: false,
            width: 200
          },
          {
            headerName: 'Holding agencia',
            valueGetter: holdingNameGetter,
            filter: true,
            width: 200
          },
          {
            headerName: 'Dirección',
            valueGetter: addressGetter,
            filter: true,
            width: 200
          },
    ]
    },
    {
      headerName: 'Datos Facturación',
      headerClass: 'column-group-2',
      children: [
        {
          headerName: 'Número de identificación',
          valueGetter: taxIdGetter,
          filter: true,
          width: 200
        },
        //queda
        {
          headerName: 'Modo de facturación',
          valueGetter: billingMomentGetter,
          filter: false,
          width: 200,
          refData: billingMomentMapping()
        },
        //queda
        {
          headerName: 'Modo de pago',
          valueGetter: paymentModeGetter,
          filter: false,
          width: 200,
          refData: paymentModeMapping()
        },
        //queda
        {
          headerName: 'Plazo de pago',
          valueGetter: paymentTimeLimitGetter,
          filter: false,
          width: 200
        },
        {
          headerName: 'Plazo de cobro',
          valueGetter: chargeTimeLimitGetter,
          filter: false,
          width: 200
        },
        //queda
        {
          headerName: 'Modo comisión agencia',
          valueGetter: agencyCommissionModeGetter,
          filter: false,
          width: 250,
          refData: agencyCommissionModeMapping()
        }
      ]
    }
]
