<template>
    <div>
        <SSRAgTable 
            v-on="$listeners" 
            :frameworkComponents="frameworkComponents" 
            :columns="columnDefs" 
            :data="rowData"
            :totalRows="totalRows" 
            :totalPages="totalPages" 
            :isLoading="isLoading" 
            @fetchData="getGridData"
            @updateSelectedItems="onSelectedItems">
        </SSRAgTable>
    </div>
</template>

<style>
/* Add !important to display: none added by v-show directive */
*[style*="display: none"] {
    display: none !important;
}
</style>

<script>
import { LicenseManager } from 'ag-grid-enterprise'
LicenseManager.setLicenseKey('CompanyName=Servoy B.V.,LicensedApplication=Servoy,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=7,LicensedProductionInstancesCount=200,AssetReference=AG-010463,ExpiryDate=11_October_2021_[v2]_MTYzMzkwNjgwMDAwMA==4c6752fe4cb2066ab1f0e9c572bc7491')
import SSRAgTable from '../components/ag-table/SSRAgTable.vue'
import appConfig from '@/../appConfig.js'
import { columnDefs } from '../utils/tables/clients'

export default {
    name: 'client-table',
    components: {
        SSRAgTable,
    },
    props: {
        filters: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            // Client grid options
            currentPage: 1,
            totalRows: 0,
            totalPages: 1,
            columnDefs: columnDefs(),
            rowData: [],
            frameworkComponents: null,
            //Items selected
            selectedRows: null,
            countSelected: 0,
            //loading flag
            isLoading: false,
            //change state
            popupActiveChangeState: false,
            popupActiveChangeStateTitle: 'Cambiar estado',
            popupActiveChangeStateType: '',
        }
    },
    watch: {},
    computed: {},
    methods: {
        getGridData({ page = 1, pageSize = 20, name = "" }) {
            this.isLoading = true;
            this.countSelected = 0
            // convert countries to name array or null if all
            const {countries, holdings, billingMoment} = this.filters
            const country = countries[0].value === 'all' ? null : countries.map(country => country.name)
            const holdingArray = holdings.length > 0 ? (holdings[0].value === 'all' ? null : holdings.map(holding => holding.value)) : null
            const billing_moment = billingMoment.value === 'all' ? null : billingMoment.value
            this.$http.get(`${appConfig.apiUrl}/panel/companies`, {
                params: {
                    country,
                    holdings: holdingArray,
                    billing_moment,
                    page,
                    per_page: pageSize,
                    name
                }
            })
                .then((response) => {
                    const { pagination, data } = response.data
                    const { total, last_page, current_page } = pagination

                    this.currentPage = current_page
                    this.totalRows = total
                    this.rowData = data
                    this.totalPages = last_page
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    this.isLoading = false
                })
            // .then(() => {
            //   this.isLoading = false
            // })
        },
        onSelectedItems(selectedItems) {
            this.selectedRows = selectedItems
            this.countSelected = selectedItems.length
        }
    },
    mounted() { }
}
</script>