<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <vx-card class="campaigns-container">
      <vx-card class="mb-2 campaign-filters" title="Filtros">
        <vs-row vs-align="flex-end" vs-type="flex" vs-justify="space-between" vs-w="12">
          <!-- INPUTS -->
          <vs-col vs-w="11">
            <vs-row class="gap-4 items-end">
              <vs-col vs-w="3">
                <p>Rango de fecha ventas:</p>
                <vs-dropdown 
                  vs-custom-content vs-trigger-click
                  class="w-full"
                >
                  <vs-input 
                    icon="calendar_today" 
                    placeholder="Rango de fechas" 
                    v-model="dateRangeInputText"
                    class="w-full"
                  />
  
                  <vs-dropdown-menu>
                    <vs-tabs class="table-selector" alignment="fixed">
                      <vs-tab label="Meses" v-on:click="showMonthTimePicker"></vs-tab>
                      <vs-tab label="Días" v-on:click="showDateRangeMonthPicker"></vs-tab>
                    </vs-tabs>
                    <!-- Month calendar -->
                    <div
                      v-show="showingMonthTimePicker"
                    >
                      <flat-pickr
                        width="100%" 
                        class="mb-1 w-full" 
                        :config="configMonthTimePicker" 
                        v-model="monthRangeData" 
                        placeholder="Rango" />
                    </div>
                    <!-- Date range calendar -->
                    <div
                      v-show="showingDateRangePicker"
                    >
                      <flat-pickr 
                        width="100%" 
                        class="mb-1 w-full" 
                        :config="configDateRangePicker" 
                        v-model="dateRangeData" 
                        placeholder="Rango"  />
                    </div>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-col>
              <vs-col vs-w="4">
                <country-select 
                  v-on:value="filters.countries = $event" 
                  :defaultSelectedCountry="'all'" 
                />
              </vs-col>
              <vs-col vs-w="4">
                <label>Mostrar reporte en USD/ARS</label>
                <vs-switch
                  vs-icon-on="check"
                  color="success"
                  v-model="filters.convertCurrency"
                >
                  <span slot="on">Si</span>
                  <span slot="off">No</span>
                </vs-switch>
              </vs-col>
            </vs-row>
          </vs-col>
          <!-- BUTTON -->
          <vs-col vs-w="1">
            <vs-col vs-w="12">
              <vs-button @click="searchData" class="mr-3 mb-2">Buscar</vs-button>
            </vs-col>
          </vs-col>
        </vs-row>
      </vx-card>
      <!-- CHARGES TABLE -->
      <billing-table
        :filters="filters"
        :filterType="selectType"
        ref="campaignsBilling"
      ></billing-table>
    </vx-card>
  </div>
</template>

<style>
.ag-header-group-cell.column-group-1 {
  background-color: #b39ddb;
}
.ag-header-group-cell.column-group-1:hover {
  background-color: #9575cd !important;
}
.ag-header-group-cell.column-group-2 {
  background-color: #ef9a9a;
}
.ag-header-group-cell.column-group-2:hover {
  background-color: #e57373 !important;
}
.ag-header-group-cell.column-group-3 {
  background-color: #a5d6a7;
}
.ag-header-group-cell.column-group-3:hover {
  background-color: #81c784 !important;
}

.campaign-filters .vx-card__header {
  padding: 10px 10px 0px 15px !important;
}

.table-selector .vs-tabs--content {
  padding: 1px;
}

.table-selector .vs-tabs--btn {
  padding: 7px!important;
}

.campaigns-container .vx-card__body {
  padding:  15px !important;
}

.font-12 * {
  font-size: 12px !important;
}

.height-32 {
  height: 32px !important;
}

/* Add !important to display: none added by v-show directive */
*[style*='display: none'] {
  display: none !important;
}
</style>

<script>
import { LicenseManager } from 'ag-grid-enterprise'
LicenseManager.setLicenseKey(
  'CompanyName=Servoy B.V.,LicensedApplication=Servoy,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=7,LicensedProductionInstancesCount=200,AssetReference=AG-010463,ExpiryDate=11_October_2021_[v2]_MTYzMzkwNjgwMDAwMA==4c6752fe4cb2066ab1f0e9c572bc7491'
)
import { AgGridVue } from 'ag-grid-vue'
import BillingTable from './BillingTable.vue'
import flatPickr from 'vue-flatpickr-component'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'
import { Spanish as SpanishLocale } from 'flatpickr/dist/l10n/es.js'
import vSelect from 'vue-select'
import CountrySelect from '../components/country-select/CountrySelect.vue'
import moment from 'moment'

import './css/campaings.scss'
import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

export default {
  components: {
    AgGridVue,
    flatPickr,
    BillingTable,
    vSelect,
    CountrySelect
  },
  data() {
    return {
      // Variables
      showingMonthTimePicker: true,
      showingDateRangePicker: false,
      filters: {
        billing: true,
        startDate: {
          from: '',
          to: ''
        },
        endDate: {
          from: '',
          to: ''
        },
        createdAt: {
          from: '',
          to: ''
        },
        salesPeriod: {
          from: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
          to: moment().format('YYYY-MM-DD')
        },
        showImpressions: true,
        convertCurrency : false,
        showConsumptionInRange : true,
        countries: []
      },
      selectType: { value: 'sales', text: 'Ventas' },
      defaultDateRange: [
        moment().subtract(1, 'months').startOf('month'),
        moment()
      ],
      dateRangeInputText: '',
      //new
      configMonthTimePicker: {
        locale: SpanishLocale,
        minDate: null,
        maxDate: new Date(),
        plugins: [
          new monthSelectPlugin({
            shorthand: true,
            dateFormat: 'M, Y',
            altFormat: 'M, Y'
          })
        ],
        inline: true,
        altInput: true,
        altFormat: 'M, Y',
        dateFormat: 'M, Y',
        mode: 'range',
        onChange: this.onMonthChange
      },
      configDateRangePicker: {
        locale: SpanishLocale,
        minDate: null,
        maxDate: new Date(),
        mode: 'range',
        altInput: true,
        altFormat: 'd/m/Y',
        dateFormat: 'd/m/Y',
        inline: true,
        onChange: this.onDayRangeChange
      },
      dateRangeData: null,
      monthRangeData: null
    }
  },
  computed: {},
  methods: {
    //Show Time Pickers
    showMonthTimePicker() {
      this.showingMonthTimePicker = true
      this.showingDateRangePicker = false
    },
    showDateRangeMonthPicker() {
      this.showingMonthTimePicker = false
      this.showingDateRangePicker = true
    },
    setDefaultRangeInputText () {
      const defaultFrom = this.defaultDateRange[0].format('MMM, YYYY')
      const defaultTo = this.defaultDateRange[1].format('MMM, YYYY')

      this.dateRangeInputText = `${defaultFrom} a ${defaultTo}`
    },
    onMonthChange (selectedDates, dateStr) {
      if (selectedDates.length === 2) {
        // get end and start dates
        const startDate = moment(selectedDates[0]).startOf('month')
        const endDate = moment(selectedDates[1]).endOf('month')
        
        this.onDayRangeChange([startDate, endDate])
        this.filters.salesPeriod.from = moment(startDate).format('YYYY-MM-DD')
        this.filters.salesPeriod.to = selectedDates.length > 1 ? moment(endDate).format('YYYY-MM-DD') : ''

        this.dateRangeInputText = dateStr
        // this.$set(this.configDateRangePicker, 'minDate', dateStr)
      }
    },
    onDayRangeChange (selectedDates, dateStr) {
      this.filters.salesPeriod.from = moment(selectedDates[0]).format('YYYY-MM-DD')
      this.filters.salesPeriod.to = selectedDates.length > 1 ? moment(selectedDates[1]).format('YYYY-MM-DD') : ''

      this.dateRangeInputText = dateStr
    },

    searchData() {
      console.log(this.$refs)
      this.$refs.campaignsBilling.getGridData({page: 1})
    }
  },
  mounted() {
    this.setDefaultRangeInputText()
  },
  beforeMount() {
    //init default dates
    this.configMonthTimePicker = {
      ...this.configMonthTimePicker,
      defaultDate: [
        this.defaultDateRange[0].toDate(),
        moment().startOf('month').toDate()
      ],
    }

    this.configDateRangePicker = {
      ...this.configDateRangePicker,
      defaultDate: [
        this.defaultDateRange[0].toDate(),
        this.defaultDateRange[1].toDate()
      ]
    }
  }
}
</script>
