var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vx-card",
        { staticClass: "campaigns-container" },
        [
          _c(
            "vx-card",
            {
              staticClass: "mb-2 campaign-filters",
              attrs: { title: "Filtros" },
            },
            [
              _c(
                "vs-row",
                {
                  attrs: {
                    "vs-align": "flex-end",
                    "vs-type": "flex",
                    "vs-justify": "space-between",
                    "vs-w": "12",
                  },
                },
                [
                  _c(
                    "vs-col",
                    { attrs: { "vs-w": "11" } },
                    [
                      _c(
                        "vs-row",
                        { staticClass: "gap-4 items-end" },
                        [
                          _c(
                            "vs-col",
                            { attrs: { "vs-w": "3" } },
                            [
                              _c("p", [_vm._v("Rango de fecha ventas:")]),
                              _c(
                                "vs-dropdown",
                                {
                                  staticClass: "w-full",
                                  attrs: {
                                    "vs-custom-content": "",
                                    "vs-trigger-click": "",
                                  },
                                },
                                [
                                  _c("vs-input", {
                                    staticClass: "w-full",
                                    attrs: {
                                      icon: "calendar_today",
                                      placeholder: "Rango de fechas",
                                    },
                                    model: {
                                      value: _vm.dateRangeInputText,
                                      callback: function ($$v) {
                                        _vm.dateRangeInputText = $$v
                                      },
                                      expression: "dateRangeInputText",
                                    },
                                  }),
                                  _c(
                                    "vs-dropdown-menu",
                                    [
                                      _c(
                                        "vs-tabs",
                                        {
                                          staticClass: "table-selector",
                                          attrs: { alignment: "fixed" },
                                        },
                                        [
                                          _c("vs-tab", {
                                            attrs: { label: "Meses" },
                                            on: {
                                              click: _vm.showMonthTimePicker,
                                            },
                                          }),
                                          _c("vs-tab", {
                                            attrs: { label: "Días" },
                                            on: {
                                              click:
                                                _vm.showDateRangeMonthPicker,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.showingMonthTimePicker,
                                              expression:
                                                "showingMonthTimePicker",
                                            },
                                          ],
                                        },
                                        [
                                          _c("flat-pickr", {
                                            staticClass: "mb-1 w-full",
                                            attrs: {
                                              width: "100%",
                                              config: _vm.configMonthTimePicker,
                                              placeholder: "Rango",
                                            },
                                            model: {
                                              value: _vm.monthRangeData,
                                              callback: function ($$v) {
                                                _vm.monthRangeData = $$v
                                              },
                                              expression: "monthRangeData",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.showingDateRangePicker,
                                              expression:
                                                "showingDateRangePicker",
                                            },
                                          ],
                                        },
                                        [
                                          _c("flat-pickr", {
                                            staticClass: "mb-1 w-full",
                                            attrs: {
                                              width: "100%",
                                              config: _vm.configDateRangePicker,
                                              placeholder: "Rango",
                                            },
                                            model: {
                                              value: _vm.dateRangeData,
                                              callback: function ($$v) {
                                                _vm.dateRangeData = $$v
                                              },
                                              expression: "dateRangeData",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "vs-col",
                            { attrs: { "vs-w": "4" } },
                            [
                              _c("country-select", {
                                attrs: { defaultSelectedCountry: "all" },
                                on: {
                                  value: function ($event) {
                                    _vm.filters.countries = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "vs-col",
                            { attrs: { "vs-w": "4" } },
                            [
                              _c("label", [
                                _vm._v("Mostrar reporte en USD/ARS"),
                              ]),
                              _c(
                                "vs-switch",
                                {
                                  attrs: {
                                    "vs-icon-on": "check",
                                    color: "success",
                                  },
                                  model: {
                                    value: _vm.filters.convertCurrency,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filters,
                                        "convertCurrency",
                                        $$v
                                      )
                                    },
                                    expression: "filters.convertCurrency",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "on" }, slot: "on" },
                                    [_vm._v("Si")]
                                  ),
                                  _c(
                                    "span",
                                    { attrs: { slot: "off" }, slot: "off" },
                                    [_vm._v("No")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vs-col",
                    { attrs: { "vs-w": "1" } },
                    [
                      _c(
                        "vs-col",
                        { attrs: { "vs-w": "12" } },
                        [
                          _c(
                            "vs-button",
                            {
                              staticClass: "mr-3 mb-2",
                              on: { click: _vm.searchData },
                            },
                            [_vm._v("Buscar")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("billing-table", {
            ref: "campaignsBilling",
            attrs: { filters: _vm.filters, filterType: _vm.selectType },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }